.descriptionPanelParent {
    position: relative;
    width: $popupWidth;
    height: $popupHeight;
    background-color: black;
    z-index: 5;

    .learnMoreClose {
        z-index: 5;
        position: absolute;
        right: 50px;
        top: 65px;
        cursor: pointer;
    }
}