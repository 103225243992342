.labsPopup {
    position: absolute;
    top: 0;
    left: 0;
    color: white;

    .labsImageTitle {
        position: absolute;
        width: 300px;
        top: 0;
        font-size: 24px;
    }

    .labsImageText {
        position: absolute;
        bottom: 10px;
        left: 30px;
        font-weight: bold;
        font-size: 20px;
    }

    .labsImageText2 {
        position: absolute;
        width: 650px;
        top: 30px;
        font-size: 20px;
    }

    .labsPopupDesc {
        width: 50%;
        margin-right: 15px;
    }

    .labsPopupDesc2 {
        width: 50%;
        margin-left: 15px;
    }

    .labsPopupImgContainer {
        position: relative;
    }

    .labsPopupLeft {
        padding-left: 50px;
        width: 48%;
        height: 620px;
        font-size: $size-medium;
        z-index: 1;
    }
    
    .labsPopupTitle {
        padding-top: 50px;
        padding-left: 50px;
        font-size: $size-large;
        font-family: MetricHPELight;
    }

    .labsPopupRight {
        margin-top: 23px;
        margin-left: 35px;
    }

    .labsQuoteBox {
        margin-top: 20px;
        text-align: center;
        line-height: 35px;
        padding: 25px 25px;
        font-family: MetricHPESemiBold;
        box-shadow: inset 0px 0px 0px 10px $brand;
        box-sizing: border-box;
        width: 800px;
        font-size: $size-medium;
    }

    .labsVideo {
        //object-fit: cover;
        //max-width: 20px;
        //max-height: auto;
        position: absolute;
        left: -80px;
        top: 35px;
        z-index: 0;
        width: 790px !important;
        height: 500px !important;
    }
}