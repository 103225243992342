.footerPopup {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(20,20,20,0.8);
    color: white;
    font-size: $size-xsmall;

    .footerPopupButton {
        margin: 5px;
        text-align: center;
        border: none;
        color: $grey;
        width: 200px;
        padding: 12px 0px;
        border-radius: 25px;
        border: 2px solid $grey;
        cursor: pointer;
    }

    .footerPopupButton-active {
        margin: 5px;
        text-align: center;
        border: none;
        color: white;
        width: 200px;
        padding: 12px 0px;
        border-radius: 25px;
        border: 2px solid white;
        cursor: pointer;
    }

    .networkFooterButton {
        margin-left: 5px;
    }
}