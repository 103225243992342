body {
  font-family: MetricHPELight;
  color: white;
  background-color: black;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: MetricHPESemibold;
  margin: 0;
}

h1 {
    font-size: 5 * $font-size;
    margin-bottom: $font-size;
  }

h3 {
  font-size: 1.2 * $font-size;
}

p {
  font-family: MetricHPELight;
  color: white;
}

strong {
  font-family: MetricHPESemiBold;
}

.boldFamily {
  font-family: MetricHPESemiBold;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1920px;
  height: 1080px;
}
.flexContainer {
  display: flex;
}

.popupContentAdjust {
  margin-top: 20px;
}

.textHightlight {
  color: $brand;
  font-family: MetricHPESemiBold;
}

// remove blue outline in chrome
*:focus { outline: 0 !important; }
