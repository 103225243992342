.whyPopup {
    position: absolute;
    top: 0;
    left: 0;
    color: white;

    .whyBox {
        border: 10px solid rgb(28,16,125);
        height: 340px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .whyBoxText {
        text-align: center;
        line-height: 1.5;
        width: 300px;
        color: grey;
    }

    .whyDescContainer {
        max-width: 800px;
    }

    .whyMoores {
        position: absolute;
        top: 375px;
        left: 31px;
        font-size: 22px;
        width: 500px;
    }

    .whyMooresText {
        position: absolute;
        font-size: 18px;
        top: 403px;
        left: 31px;
        width: 500px;
    }

    .whyQuoteBox {
        margin-top: 40px;
        text-align: center;
        line-height: 35px;
        padding: 45px 20px;
        font-family: MetricHPESemiBold;
        box-shadow: inset 0px 0px 0px 10px $brand;
        box-sizing: border-box;
        width: 800px;
    }

    .whyPopupLeft {
        padding-top: 50px;
        padding-left: 50px;
        padding-right: 50px;
        width: 50%;
        height: 750px;
        font-size: $size-medium;
    }
    
    .whyPopupTitle {
        font-size: $size-large;
        font-family: MetricHPELight;
    }

    .whyPopupDesc {
        max-width: 50%;
        padding-right: 10px;
    }

    .whyPopupDesc2 {
        max-width: 50%;
        padding-left: 10px;
    }

    .whyPopupRight {
        margin-top: 69px;
    }

    .whyImageContainer {
        position: relative; 
        padding-left: 20px;
    }

    img {
        //object-fit: cover;
        //max-width: 20px;
        //max-height: auto;
        width: 100%;
        height: auto;
        background-color: black;
    }
}