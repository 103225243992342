.recurrentPopup {
    position: absolute;
    top: 0;
    left: 0;
    color: white;

    .recurrentPopupLeft {
        padding-top: 50px;
        padding-left: 50px;
        width: 50%;
        height: 750px;
        font-size: $size-medium;
    }
    
    .recurrentPopupTitle {
        font-size: $size-large;
        font-family: MetricHPELight;
    }

    .recurrentPopupDesc2 {
        padding-left: 50px;
        margin-right: 50px;
    }

    .recurrentPopupRight {
        margin-top: 250px;
    }

    img {
        //object-fit: cover;
        //max-width: 20px;
        //max-height: auto;
        width: 100%;
        height: auto;
    }
}