// sizes
$size-xsmall: 13px;
$size-small: 15px;
$size-medium: 24px;
$size-large: 64px;

$inner-padding-x: 3vmin;
$inner-padding-y: 4.5vmin;

$font-size: 4vmin;
$font-size-small: 2vmin;
$grey: grey;
$brand: #01a982;

//$popupWidth: 1690px;
//$popupHeight: 850px;
$popupWidth: 1720px;
$popupHeight: 880px;