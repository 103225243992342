.unconPopup {
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/unconventional_image.jpg');
    background-repeat: no-repeat;
    //background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    z-index: 4;
    top: 0;
    left: 0;
    color: white;
    display: flex;

    .unconContent {
        width: 40%;
        margin-right: 25px;
    }

    .unconContent2 {
        width: 40%;
    }

    .unconFade {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 50%, rgba(0, 0, 0, 0) 80%);
        z-index: 0;
    }

    .unconPopupLeft {
        padding-top: 50px;
        padding-left: 50px;
        width: 55%;
        z-index: 0;
    }
    
    .unconPopupTitle {
        font-size: $size-large;
        font-family: MetricHPELight;
    }

    .unconPopupDesc {
        display: flex;
        font-size: $size-medium;
    }

    .unconQuoteBox {
        margin-top: 20px;
        text-align: center;
        line-height: 35px;
        padding: 45px 25px;
        font-family: MetricHPESemiBold;
        box-shadow: inset 0px 0px 0px 10px $brand;
        box-sizing: border-box;
        width: 800px;
        font-size: $size-medium;
    }
}