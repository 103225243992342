.introPage {
    width: 1920px;
    height: 1080px;
    position: relative;

    .introPagePanel {
        position: absolute;
        width: 840px;
        height: 1080px;
        //background-color: rgba(0,0,0,0.7);
        background-image: url('../assets/images/intro_bk.png');

        .introBKImg {
            position: absolute;
            width: 100%;
            z-index: 0;
            left: 0;
            top: 0;
        }

        .introBorder {
            width: 690px;
            border-bottom: 2px $brand solid;
            padding-top: 60px;
        }

        .introPageContent {
            margin-top: 80px;
            margin-left: 80px;
        }

        .introPageTitle {
            margin-top: 50px;
            font-size: $size-large;
            font-family: MetricHPESemiBold;
        }

        .introPageDesc {
            width: 670px;
            margin-top: 50px;
            font-size: 24px;
        }

        img {
            width: 200px;
            height: auto;
        }
    }

    .startButton {
        position: absolute;
        width: 350px;
        line-height: 3.5;
        background-color: $brand;
        bottom: 100px;
        left: 81px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        cursor: pointer;
    }

    .introPlayer {
        width: 1920px !important;
        height: 1080px !important;
        max-width: 1920px;
        max-height: 1080px;
        
    }

    .introPlayerContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 1920px;
        max-width: 1920px;
        height: 1080px;
        max-height: 1080px;
        overflow: hidden;
    }

}