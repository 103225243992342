.controlInfoPiece {
    height: 150px;
    align-items: center;
    font-size: $size-medium;

    .controlInfoImg {
        width: 100px;
        text-align: center;
    }

    .controlInfoText {
        margin-left: 30px;
    }
}