.accPopup {
    width: 100%;
    height: 100%;
    position: relative;
    /*
    background-image: url('../assets/images/what_image.png');
    background-repeat: no-repeat;
    background-size: cover;
    //background-attachment: fixed;
    background-position: center center;
    */
    top: 0;
    left: 0;
    color: white;
    display: flex;
    overflow: hidden;

    .accZfix {
        z-index: 4;
    }

    .accPopupLeft {
        padding-top: 50px;
        padding-left: 50px;
        width: 22%;
    }
    
    .accPopupTitle {
        font-size: $size-large;
        font-family: MetricHPELight;
    }

    .accPopupDesc {
        margin-top: 40px;
        font-size: $size-medium;
    }

    .whatBackground {
        position: absolute;
        width: $popupWidth;
        z-index: 0;
        
    }

    .whatFade {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 20%, rgba(0, 0, 0, 0) 60%);
    }
}