@font-face {
 font-family: MetricHPEBold;
 src: url("../assets/fonts/MetricHPE-Web-Bold.woff") format("woff");
 font-weight: normal;
 font-style: normal;
}

@font-face {
 font-family: MetricHPELight;
 src: url("../assets/fonts/MetricHPE-Web-Light.woff") format("woff");
 font-weight: normal;
 font-style: normal;
}

@font-face {
 font-family: MetricHPESemiBold;
 src: url("../assets/fonts/MetricHPE-Web-Semibold.woff") format("woff");
 font-weight: normal;
 font-style: normal;
}

@font-face {
 font-family: HPESimple;
 src: url("../assets/fonts/SimRg.otf");
 font-weight: normal;
 font-style: normal;
}
