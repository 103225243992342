.learnMoreButtons {
    color: white;
    font-size: $size-xsmall;
    .learnMoreButton {
        margin: 15px;
        text-align: center;
        border: none;
        width: 200px;
        padding: 12px 0px;
        border-radius: 25px;
        border: 2px solid white;
        cursor: pointer;
    }
}