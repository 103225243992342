.infoTooltip {

  .infoPanel {
      opacity: 1 !important;
    background-color: white !important;
    width: 200px;
  }

  .info-button {
    cursor: pointer;
  }

  .info-device-connected {
    position: absolute;
    z-index: 4;
    bottom: 316px;
    left: 160px;
  }

  .info-device-breakdown {
    position: absolute;
    bottom: 133px;
    left: 155px;
  }

  .info-dashboard {
    position: absolute;
    bottom: 317px;
    left: 1165px;
  }

  .info-command {
    position: absolute;
    bottom: 133px;
    left: 520px;
  }

  .info-dataflow {
    position: absolute;
    bottom: 133px;
    left: 1160px;
  }

  .info-neural {
    position: absolute;
    bottom: 133px;
    left: 1630px;
  }

  img {
      width: 13px;
      height: auto;
  }
}