.controlPopup {
    position: absolute;
    top: 0;
    left: 0;
    color: white;

    .controlInfoContainer {
        padding-top: 30px;
        width: $popupWidth;
        height: $popupHeight;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .controlPopupTitle {
        position: absolute;
        margin-top: 50px;
        margin-left: 50px;
        font-size: $size-large;
        font-family: MetricHPELight;
    }
    
    .controlInfoPieceRight {
        margin-left: 150px;
    }

}