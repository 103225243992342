.app {
  position: relative;
  min-width: 1920px;
  min-height: 1080px;
  max-width: 1920px;
  max-height: 1080px;
  overflow: hidden;
  color: black;

  .circle-button {
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: white;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }

  .circle-button.large {
    padding: 28px;
    font-size: 16px;
    margin: 8px 4px;
  }

  .control {
    position: absolute;
    z-index: 1;
  }

  .controlContainer {
    position: absolute;
    width: 1920px;
    height: 1080px;
  }

  .control-flip {
    transform: rotate(0deg);
    bottom: 160px;
    left: 940px;
  }

  .control-left {
    transform: rotate(90deg);
    bottom: 20px;
    left: 325px;
  }

  .control-right {
    transform: rotate(270deg);
    top:20px;
    right: 325px;
  }

  .commandTextLoop {
    font-family: Courier;
    font-size: 10px;
    text-align: left;
    width: 350px;
    max-width: 350px;
    height: 105px;
    max-height: 105px;
    background-color: black;
    overflow: hidden;
  }

  .commandTextLoop-large {
    border: 2px solid  rgba(68, 68, 68, 0.4);
    padding: 4px;
    font-family: Courier;
    font-size: 10px;
    text-align: left;
    width: 600px;
    max-width: 600px;
    height: 105px;
    max-height: 105px;
    background-color: rgba(0,0,0,0.4);
    overflow: hidden;
  }

  .commandTextLoop-flip {
    position: absolute;
    bottom: 17px;
    left: 424px;
  }

  .commandTextLoop-left {
    position: absolute;
    top: 185px;
    left: -110px;
    transform: rotate(90deg);
  }

  .commandTextLoop-right {
    position: absolute;
    bottom: 185px;
    right: -110px;
    transform: rotate(270deg);
  }

  .dataText {
    position: absolute;
    left: 0;
    top: 0;
    width: 1920px;
    height: 1080px;
  }

  .dataText-flip {
    position: absolute;
    left: 0;
    top: 0;
    width: 1920px;
    height: 1080px;
  }

  .dataText-flip img{
    width: 1920px;
    height: 1080px;
  }

  .descriptionPanelBackground {
    position: absolute;
    z-index: 4;
    background-color: rgba(0,0,0,0.5);
    width: 1920px;
    height: 1080px;
  }

  .graphBorder {
    position: absolute;
    top: 92px;
    left: 442px;
    width: 3px;
    height: 827px;
    background-color: white;
  }

  .graphBorder-right {
    position: absolute;
    top: 158px;
    right: 440px;
    width: 3px;
    height: 827px;
    background-color: white;
  }

  .graphBorder-flip {
    position: absolute;
    bottom: 299px;
    left: 78px;
    width: 828px;
    height: 3px;
    background-color: white;
  }

  .input {
    position: absolute;
    z-index: 3;
    background-color: rgba(168, 63, 63, 0.7);
  }

  .input-flip {
    /*
    width: 1470px;
    top: 150px;
    left: 170px;
    */
    width: 875px;
    bottom: 202px;
    left: 50px;
  }

  .input-left {
    transform: rotate(90deg);
    width: 875px;
    top: 452px;
    left: -45px;
  }

  .input-right {
    transform: rotate(270deg);
    width: 875px;
    top: 518px;
    right: -45px;
  }

  .flipButton {
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 40px;
    right: 500px;
    z-index: 1;
    background-color: white;
  }

  .homeButton {
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 5;
    cursor: pointer;
  }

  .learnMoreSection {
    position: absolute;
    right: 12px;
    bottom: 17px;
  }

  .pause-button {
    margin-left: 2px;
    margin-bottom: 10px;
    width: 50px;
    height: 50px;
    background: url('../assets/images/pause.png');
    background-size: 45px;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .player {
    width: 1920px !important;
    height: 1080px !important;
    max-width: 1920px;
    max-height: 1080px;
  }

  .player-flip video {
    position: absolute;
    transform: rotate(90deg);
    /*bottom: 870px;*/
    bottom: 920px;
    left: 0;
    /*transform-origin: bottom left;*/
    width: 1920px !important;
    height: 1080px !important;
    object-fit: cover;
    z-index: 0;
    visibility: visible;
  }

  .play-button {
    margin-left: 2px;
    margin-bottom: 10px;
    width: 50px;
    height: 50px;
    background: url('../assets/images/play.png');
    background-size: 45px;
    background-repeat: no-repeat;
    cursor: pointer;
  }


  .playerContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 1920px;
    max-width: 1920px;
    height: 1080px;
    max-height: 1080px;
    overflow: hidden;
  }

  .popup {
    z-index: 25;
    min-width: 1080px;
    min-height: 570px;
    background-color: rgba(0,0,0,0.7);
  }

  .popup-left {
    position: absolute;
    left: 570px;
    top: 0px;
    transform: rotate(90deg);
    transform-origin: top left;
  }

  .popup-right {
    position: absolute;
    right: 575px;
    top: 0px;
    transform: rotate(270deg);
    transform-origin: top right;
  }

  .popup-flip {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  .popup .imgPopup {
    position: relative;
    min-width: 100%;
    min-height: 570px;
  }

  .popup .imgPopup img{
    cursor: pointer;
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 500px;
    height: auto;
  }

  .networkButtonImg-flip{
    cursor: pointer;
    z-index: 2;
    position:absolute;
    bottom: 50px;
    right: 315px;
  }

  .networkButtonImg-left {
    cursor: pointer;
    z-index: 2;
    position:absolute;
    transform: rotate(90deg);
    bottom: 30px;
    left: 50px;
  }

  .networkButtonImg-right {
    cursor: pointer;
    z-index: 2;
    position:absolute;
    transform: rotate(270deg);
    top: 30px;
    right: 50px;
  }

  .normal-button {
    width: 90px;
    height: 90px;
    background: url('../assets/images/normal-txt.png');
    background-size: 90px;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .slow-button {
    width: 90px;
    height: 90px;
    background: url('../assets/images/slow-txt.png');
    background-size: 90px;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  /* SLIDER override */
  .slider {
    z-index: 1;
    height: 100px;
    border-radius: 5px;
    /*border-top: 10px white solid;
    background-image: linear-gradient(to right, rgba(255, 0, 0, 1), rgba(0, 128, 0, 1));*/
    background:transparent!important;
    background-image: rgba(0, 0, 0, 0.0) !important;
    outline: none;
    opacity: 1;
    transition: opacity .2s;
    -webkit-appearance: none;
    overflow: visible !important;
  }

  .slider::-webkit-slider-thumb {
    width: 50px;
    height: 153px;
    border: 0;
    background: url('../assets/images/playhead.png');
    background-size: 36px;
    background-repeat: no-repeat;
    background-position: 10px 9px;
    display:block;
    cursor: pointer;
    -webkit-appearance: none;
  }

  .slider::-moz-range-thumb {
    width: 50px;
    height: 153px;
    border: 0;
    background: url('../assets/images/playhead.png');
    background-size: 36px;
    background-repeat: no-repeat;
    background-position: 10px 9px;
    display:block;
    cursor: pointer;
    -moz-appearance: none;
  }

}
