.labsNovelPopup {
    position: absolute;
    top: 0;
    left: 0;
    color: white;

    .labsNovelPopupDesc {
        width: 50%;
        margin-right: 15px;
    }

    .labsNovelPopupDesc2 {
        width: 50%;
        margin-left: 15px;
    }

    .labsNovelPopupLeft {
        padding-top: 50px;
        padding-left: 50px;
        width: 48%;
        height: 750px;
        font-size: $size-medium;
    }
    
    .labsNovelPopupTitle {
        font-size: $size-large;
        font-family: MetricHPELight;
    }

    .labsNovelPopupRight {
        margin-top: 150px;
        width: 48%;
    }

    .labsNovelVideo {
        position: absolute;
        top: 125px;
        right: 100px;
        z-index: 0;
        //width: 790px !important;
        height: 500px !important;
    }

    img {
        //object-fit: cover;
        //max-width: 20px;
        //max-height: auto;
        margin-left: 150px;
        width: 62%;
        height: auto;
        background-color: black;
    }
}